import { questions, results } from "./fake_db";

const BASE_URL = "https://valuation.metrix.email/api";

export const pullSlidesConfig = async (callback, errorCallback) => {
  try {
    console.log("fetch");
    const response = await fetch(`${BASE_URL}/questions`);
    const data = await response.json();
    if (!response.ok) throw Error("Response error");
    console.log("data", data);
    callback(data);
  } catch (e) {
    errorCallback(true);
    console.log(e);
  }
  // await new Promise((resolve) => setTimeout(resolve, 300));
  // callback(questions);
};

export const postQuestions = async (data, callback, errorCallback) => {
  console.log("data", data);
  try {
    const response = await fetch(`${BASE_URL}/evaluation?verbose=True`, {
      method: "POST",
      body: JSON.stringify(data),
    });
    if (!response.ok) throw Error("Response error");
    const results = await response.json();
    console.log("results", results);
    callback(results);
    localStorage.removeItem("formData");
  } catch (e) {
    errorCallback(true);
    console.log(e);
  }
  // await new Promise((resolve) => setTimeout(resolve, 2000));
  // console.log('results', results);
  // callback(results);
};

export const sendConsultationScheduled = async (data) => {
  try {
    await fetch(`${BASE_URL}/consultation_scheduled`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  } catch (e) {
    console.log(e);
  }
};
