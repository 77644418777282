import React from "react";
import { ArrowIcon } from "./Icons";
import ConfirmButton from "./ConfirmButton";
import classNames from "classnames";

const ContentWrapper = ({
  children,
  serialNumber,
  title,
  confirmText,
  showConfirmHint,
  onConfirm,
  leftAligned,
  bottomSection,
}) => {
  return (
    <div
      style={{ height: "100vh" }}
      className={classNames(
        "py-4 md:px-16 text-white flex flex-col justify-center items-start",
        {
          // "xs:items-start": leftAligned,
          "xs:items-center": !leftAligned,
        }
      )}
    >
      {title && (
        <div className="text-left flex items-center relative text-lg md:text-2xl">
          <div
            className="md:absolute flex items-center px-2 md:px-3"
            style={{ right: "100%" }}
          >
            <p className="pr-2 font-semibold">{serialNumber}</p>
            <ArrowIcon />
          </div>
          <p>{title}</p>
        </div>
      )}
      {children}
      {confirmText && (
        <ConfirmButton
          text={confirmText}
          onConfirm={onConfirm}
          showHint={showConfirmHint}
          className="mt-8"
        />
      )}
      <div className="opacity-0">A</div>
      {bottomSection}
    </div>
  );
};

export default ContentWrapper;
