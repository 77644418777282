import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CheckIcon, CheckOptionIcon, CrossIcon } from "./Icons";
import classNames from "classnames";
import { OPTIONS_KEYS } from "./CardsInput";

export const isMobileSize = () => window.innerWidth <= 480;

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: 8,
      maxHeight: isMobileSize() ? 250 : 320,
      maxWidth: 250,
      backgroundColor: "#27806E",
    },
  },
};
const SX = {
  root: {
    paddingBottom: "1rem !important",
    backgroundColor: "white !important",
  },
  label: {
    color: "white",
    fontWeight: 400,
    fontSize: {
      xs: "1.25rem",
      md: "1.5rem",
    },
    opacity: "50%",
    "&.MuiInputLabel-root.Mui-focused": {
      color: "white",
      fontSize: {
        xs: "1.125rem",
        md: "1.5rem",
      },
    },
    "&.MuiInputLabel-root.MuiFormLabel-filled": {
      fontSize: {
        xs: "1.125rem",
        md: "1.5rem",
      },
    },
  },
  "& input": {
    color: "white",
    fontSize: {
      xs: "1.25rem",
      md: "2rem",
    },
  },
  "& .MuiSelect-select": { minHeight: "48px !important" },
  "& .MuiInputBase-input": { color: "#fff !important" },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#fff8 !important",
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "#fff8 !important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff8 !important",
  },
  "& .MuiInput-root:hover::before": {
    borderColor: "#fff8 !important",
  },
  "& .MuiFormHelperText-root": { color: "#fff8 !important" },
  "& .MuiInputLabel-root.Mui-error": { color: "#fff8 !important" },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#FABE28 !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1 !important",
    borderColor: "#fff8 !important",
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1 !important",
    borderColor: "#fff8 !important",
  },
};

const getItem = ({ isSelected, text, index }) => {
  return (
    <div
      className={
        "flex items-center px-4 pb-2 mt-2 border-b text-white w-full box-border"
      }
    >
      <div className="flex items-center justify-start">
        <div
          className={classNames(
            "mr-3 rounded h-8 w-8 flex items-center justify-center font-bold text-base",
            {
              "bg-[#1F6658]": !isSelected,
              "bg-white": isSelected,
            }
          )}
        >
          {isSelected ? <CheckOptionIcon /> : OPTIONS_KEYS[index]}
        </div>
        <div className="font-semibold text-base">{text}</div>
      </div>
    </div>
  );
};

export const SearchQuestion = ({
  isMultiSelect,
  selectedValue,
  onClick: _onClick,
  getIsSelected,
  options,
  labelText,
  setNextBlocked
}) => {
  const getInputContent = () => {
    const data = isMultiSelect ? selectedValue : [selectedValue];
    return data.map((value) => {
      const text = options.find((item) => item.id === value)?.text;
      return (
        <div className="z-10 px-2 py-1 bg-green-default rounded flex items-center justify-start">
          {text}
          {/*<CrossIcon*/}
          {/*  onCLick={() => {*/}
          {/*    console.log("onCLick", value);*/}
          {/*    _onClick(value);*/}
          {/*  }}*/}
          {/*/>*/}
        </div>
      );
    });
  };
  const _labelText =
    labelText ||
    (isMultiSelect ? "Select one or more options" : "Select your answer");
  return (
    <div className="w-full mt-9">
      <FormControl fullWidth sx={SX}>
        <InputLabel id="demo-multiple-chip-label">{_labelText}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          label={_labelText}
          multiple={isMultiSelect}
          value={selectedValue || []}
          onChange={() => {}}
          variant="standard"
          IconComponent={() => null}
          onOpen={()=> setNextBlocked(true)}
          onClose={()=> setNextBlocked(false)}
          renderValue={() => (
            <Box
              className="z-[9999] py-2 max-w-full flex-wrap"
              sx={{ display: "flex", gap: 0.5 }}
            >
              {getInputContent()}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map((item, index) => {
            const { text, id } = item;

            return (
              <MenuItem
                key={id}
                value={id}
                className="w-full my-0 hover:bg-[#2a272782] bg-transparent"
                onClick={() => _onClick(id)}
              >
                {getItem({ isSelected: getIsSelected(id), text, index })}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
