import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import RainforestLabel from "./RainforestLabel";
import { CrossIcon, GlassesIcon, RocketIcon } from "./Icons";

const getCard = ({ icon, title, list }) => {
  return (
    <div className="p-3 md:p-8 bg-white rounded-lg flex flex-col items-start md:w-[23.25rem]">
      {icon}
      <div className="mt-4 md:mt-6 text-gray-black font-bold text-2xl md:text-4xl font-cormorant">
        {title}
      </div>
      <div className="mt-4 md:mt-6">
        {list.map((item) => (
          <div className="flex items-start justify-start text-gray-black mb-2">
            <CrossIcon className="mr-3 m-2 flex-shrink-0" />
            <div>{item}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
const getResultCards = (striveList, improveList) => {
  const cards = [
    { icon: <RocketIcon />, title: "Where you strive", list: striveList },
    { icon: <GlassesIcon />, title: "Things to improve", list: improveList },
  ];
  return (
    <div className="flex flex-wrap gap-4 mt-8 md:mt-16">
      {cards.map(getCard)}
    </div>
  );
};

const getCurrencyView = (value = 0) =>
  `${value}`.replace(/\d(?=(\d{3})+$)/g, "$&,");

const ResultsPageTopSection = ({
  name,
  valuationValue,
  striveList,
  improveList,
  schedule_call,
}) => {
  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }, []);
  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);
  return (
    <div
      className="flex flex-col items-center justify-start transition-all duration-1000"
      style={{ opacity }}
    >
      <RainforestLabel className="mt-4 md:mt-20" hideBackground />
      <div className="text-center font-bold  text-2xl md:text-5xl mt-8 font-cormorant">
        {name}, your business is valued at:
      </div>
      <div className="font-bold text-5xl md:text-[5rem] mt-4 md:mt-6">
        $ {getCurrencyView(valuationValue[1].toFixed(0))}
      </div>
      {schedule_call && (
        <div className="flex items-center justify-center h-16 font-bold text-sm mt-6 md:mt-16">
          <a href="#talk-to-experts">
            <div className="h-16 w-48 bg-white text-gray-black rounded-r-lg rounded-b-lg flex items-center justify-center text-center">
              Schedule Call Now
            </div>
          </a>
          {/*<div className="h-16 w-32 md:w-40 rounded-r-lg rounded-b-lg flex items-center justify-center text-center border border-white">*/}
          {/*  Share Results*/}
          {/*</div>*/}
        </div>
      )}
      {getResultCards(striveList, improveList)}
      <div className="mt-8 md:mt-16 mb-6 md:mb-12 text-xs align-center text-center max-w-[36rem] opacity-80">
        Disclaimer: While we strive to achieve an accurate valuation this
        exercise is based on a very small dataset compared to what is used when
        calculating the final amount for a deal. We appreciate you taking your
        time to use our tool. Reach out to us for any further questions.
      </div>
    </div>
  );
};

ResultsPageTopSection.propTypes = {};

export default ResultsPageTopSection;
