import React from "react";
import SafeImage from "./SafeImage";
import {Button} from "@mui/material";
import classNames from "classnames";
import {useKeyPress} from "../hooks";
import {CheckOptionIcon} from "./Icons";
import {isMobileSize, SearchQuestion} from "./SearchQuestion";

export const OPTIONS_KEYS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const CardsInput = ({
                      cards: _cards,
                      selectedValue,
                      onCardClick,
                      active,
                      multiSelect,
                      labelText,
                      setNextBlocked,
                    }) => {
  let cards = [..._cards];
  const isImageCards = !!cards[0]?.image_url;
  const keys = OPTIONS_KEYS.slice(0, cards.length);
  const keyPressHandler = (key) => {
    if (!active) return;
    const capsKey = key.toUpperCase();
    const keyIndex = keys.findIndex((item) => item === capsKey);
    if (keyIndex < 0) return;
    const newValue = cards[keyIndex].id;
    onCardClick(newValue);
  };
  useKeyPress(null, keyPressHandler);
  const getIsSelected = (value) => {
    if (multiSelect) {
      return !!selectedValue?.find((item) => value === item);
    } else {
      return value === selectedValue;
    }
  };
  const getCard = ({image_url: url, text, id}, index) => {
    const isSelected = getIsSelected(id);
    return (
      <Button
        onClick={() => onCardClick(id)}
        className="p-0 flex-shrink-0"
        sx={{
          "& .MuiTouchRipple-root span": {
            backgroundColor: "white !important",
            borderRadius: "50%",
          },
        }}
      >
        <div className="bg-white absolute w-full h-full opacity-10 hover:opacity-30"/>
        <div
          className={
            "flex-shrink-0 flex flex-col justify-between p-3 rounded-lg w-40 md:w-56 h-64 md:h-72 border text-white"
          }
        >
          <div
            className="relative flex items-center justify-center flex-grow rounded-lg overflow-hidden"
            style={{backgroundColor: "#fff6"}}
          >
            <SafeImage src={url} className="object-cover max-w-[110%]"/>
          </div>
          <div className="flex items-center justify-start mt-3 text-xs md:text-base">
            <div
              className={classNames(
                "mr-3 rounded h-8 w-8 flex items-center justify-center font-bold text-base flex-shrink-0",
                {
                  "bg-green-default": !isSelected,
                  "bg-white": isSelected,
                }
              )}
            >
              {isSelected ? <CheckOptionIcon/> : OPTIONS_KEYS[index]}
            </div>
            <div className="font-semibold text-xl">{text}</div>
          </div>
        </div>
      </Button>
    );
  };

  const getOptions = () => {
    return (
      <div className="mt-12 flex gap-4 flex-col flex-wrap w-full md:w-auto md:max-h-[50%]">
        {cards.map(({text, id}, index) => {
          const isSelected = getIsSelected(id);
          return (
            <Button
              sx={{
                "& .MuiTouchRipple-root span": {
                  backgroundColor: "white !important",
                  borderRadius: "50%",
                },
              }}
              onClick={() => onCardClick(id)}
              className="p-0 flex items-center w-full normal-case overflow-hidden"
            >
              <div className="bg-white absolute w-full h-full opacity-10 hover:opacity-30"/>
              <div
                className={
                  "flex items-center px-4 rounded-lg border text-white w-full box-border min-h-[4rem]"
                }
              >
                <div
                  className={classNames(
                    "mr-3 rounded h-8 w-8 flex-shrink-0 flex items-center justify-center font-bold text-base",
                    {
                      "bg-green-default": !isSelected,
                      "bg-white": isSelected,
                    }
                  )}
                >
                  {isSelected ? <CheckOptionIcon/> : OPTIONS_KEYS[index]}
                </div>
                <div className="font-semibold text-base md:text-xl">{text}</div>
              </div>
            </Button>
          );
        })}
      </div>
    );
  };

  if (cards.length > (isMobileSize() ? 5 : 6)) {
    // cards = [..._cards].sort((a, b) => a.text?.localeCompare(b.text));
    return (
      <SearchQuestion
        selectedValue={selectedValue}
        onClick={onCardClick}
        getIsSelected={getIsSelected}
        isMultiSelect={multiSelect}
        options={cards}
        labelText={labelText}
        setNextBlocked={setNextBlocked}
      />
    );
  }

  if (isImageCards)
    return (
      <div className="flex gap-4 mt-6 md:mt-12 w-full justify-start overflow-auto">
        {cards.map(getCard)}
      </div>
    );
  return getOptions();
};

CardsInput.propTypes = {};

export default CardsInput;
