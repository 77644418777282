import React from "react";
import {DocumentIcon, DollarIcon} from "./Icons";
import SuccessIcon from "../images/success_icon.png";
import LeafIcon from "../images/leaf_icon.png";
import SafeImage from "./SafeImage";

const getCard = ({icon, title, text}) => {
  return (
    <div className="flex flex-col items-center md:items-start text-center md:text-left">
      <div className="mt-4 md:mt-0 w-16">{icon}</div>
      <div className="mt-3 md:mt-6 font-extrabold md:text-lg">{title}</div>
      <div className="mt-2 md:mt-4">{text}</div>
    </div>
  );
};

const ResultsMiddleSection = () => {
  const cards = [
    {
      icon: <SafeImage src={LeafIcon} className="h-[61px]"/>,
      title: "Tech for growth",
      text: "We create in-house tools to automate, improve, and optimise brand acceleration",
    },
    {
      icon: <DollarIcon/>,
      title: "Entrepreneurial brand team",
      text: "Our experienced growth operators are consumer experts, many of whom are former Amazon entrepreneurs and DTC operator",
    },
    {
      icon: <SafeImage src={SuccessIcon} className="h-[61px]"/>,
      title: "Success driven",
      text: "We’re focused on the brands’ financial returns and work towards success.",
    },
    {
      icon: <DocumentIcon/>,
      title: "International and product expansion",
      text: "See your brand go beyond borders, to new marketplaces and channels.",
    },
  ];
  return (
    <div className="px-2 md:px-0 md:w-8/12 mx-auto mt-8 md:mt-24">
      <div className="mx-auto font-bold text-[1.5rem] md:text-[3.625rem] text-center md:text-left font-cormorant">
        Rainforest is an e-commerce <br/> aggregator and brand builder.
      </div>
      <div className="mt-8 md:mt-20 mb-8 md:mb-20 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-16">
        {cards.map(getCard)}
      </div>
    </div>
  );
};

ResultsMiddleSection.propTypes = {};

export default ResultsMiddleSection;
