import React from "react";
import { use100vh } from "react-div-100vh";
import RainforestLabel from "./RainforestLabel";
import UpDownButtons from "./UpDownButtons";
import classNames from "classnames";
import background from "../images/rf_bg_cr3.png";

const QuestionnaireWrapper = ({
  children,
  hideUpDownButtons,
  shading,
  onUpClick,
  onDownClick,
  carouselView,
  bottomSection,
  nextEnabled
}) => {
  const height = use100vh();
  return (
    <div
      className={classNames(
        "overflow-hidden flex flex-col items-center w-full relative transition-all duration-1000",
        {
          "justify-center": carouselView,
          "rounded-3xl justify-start": !carouselView,
        }
      )}
      style={{
        height: !carouselView ? "auto" : height,
        background: `url(${background}), #27806E`,
        backgroundSize: "auto 150vh",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className="w-full h-full absolute bg-black z-10 transition-opacity"
        style={{ opacity: shading ? 0.35 : 0 }}
      />
      <div className="z-10 max-w-[90%] xl:max-w-[1080px] md:max-w-[80%]">{children}</div>
      {carouselView && (
        <div className="w-full md:w-auto right-0 bottom-0 absolute flex items-center justify-between px-4 md:px-0 md:mr-8 mb-4 md:mb-8 z-20">
          <UpDownButtons
            className="mr-6"
            hidden={hideUpDownButtons}
            onUpClick={onUpClick}
            nextEnabled={nextEnabled}
            onDownClick={onDownClick}
          />
          <RainforestLabel className="ml-auto" />
        </div>
      )}
      {bottomSection && (
        <div className="w-full bottom-0 flex flex-shrink-0 absolute items-center justify-center z-20">
          {bottomSection}
        </div>
      )}
    </div>
  );
};

export default QuestionnaireWrapper;
