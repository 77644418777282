import React from "react";
import classNames from "classnames";
import { RainforestLogo } from "./Icons";

const RainforestLabel = ({ className, hideBackground }) => {
  return (
    <div
      className={classNames(
        "flex items-center rounded-lg h-12",
        { "bg-green-default": !hideBackground },
        className
      )}
    >
      <div className="flex items-center justify-center px-4">
        <p className="hidden md:block mr-2 text-white font-normal text-xs">POWERED BY</p>
        <RainforestLogo />
      </div>
    </div>
  );
};

RainforestLabel.propTypes = {};

export default RainforestLabel;
