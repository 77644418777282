export const RainforestBackground = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 1440 800"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2">
        <path
          d="M495.924 270.44L483.749 273.702L210.157 347.011L190.838 352.187C106.556 374.77 21.2192 361.076 -48.8426 320.625C-118.89 280.168 -173.413 213.124 -195.992 128.858C-218.576 44.5727 -204.883 -40.7671 -164.448 -110.827C-124 -180.884 -56.9426 -235.397 27.3362 -257.998L495.924 270.44ZM495.924 270.44L414.179 -34.637C391.595 -118.922 337.066 -185.981 267.005 -226.432C196.946 -266.881 111.631 -280.566 27.3431 -258L495.924 270.44ZM224.813 401.708L404.81 353.478L498.405 328.399L537.928 317.809L545.958 315.657L565.276 310.481L560.1 291.162L557.948 283.132L468.875 -49.2929L468.875 -49.2946C415.613 -248.002 211.401 -365.925 12.688 -312.68C-186.024 -259.435 -303.934 -55.2016 -250.688 143.513C-199.188 335.716 -6.37872 452.35 186.039 411.551L186.176 412.06L205.494 406.884L224.813 401.708Z"
          fill="white"
          stroke="white"
          stroke-width="40"
        />
        <path
          d="M944.446 529.394L956.621 526.132L1230.21 452.824L1249.53 447.647C1333.81 425.064 1419.15 438.758 1489.21 479.21C1559.26 519.666 1613.78 586.71 1636.36 670.977C1658.95 755.261 1645.25 840.6 1604.82 910.659C1564.37 980.717 1497.31 1035.23 1413.03 1057.83L944.446 529.394ZM944.446 529.394L1026.19 834.471C1048.78 918.756 1103.3 985.815 1173.37 1026.27C1243.42 1066.72 1328.74 1080.4 1413.03 1057.83L944.446 529.394ZM1215.56 398.127L1035.56 446.357L941.965 471.435L902.442 482.025L894.412 484.177L875.094 489.353L880.27 508.672L882.422 516.702L971.495 849.127L971.495 849.129C1024.76 1047.84 1228.97 1165.76 1427.68 1112.51C1626.39 1059.27 1744.3 855.036 1691.06 656.321C1639.56 464.118 1446.75 347.484 1254.33 388.284L1254.19 387.774L1234.88 392.95L1215.56 398.127Z"
          fill="white"
          stroke="white"
          stroke-width="40"
        />
        <path
          d="M849.661 175.656L861.835 172.394L1135.43 99.0854L1154.75 93.909C1239.03 71.3256 1306.08 16.7975 1346.53 -53.2653C1386.97 -123.326 1400.66 -208.649 1378.09 -292.915C1355.5 -377.2 1300.97 -444.26 1230.93 -484.716C1160.87 -525.163 1075.54 -538.844 991.25 -516.278L849.661 175.656ZM849.661 175.656L767.915 -129.42C745.331 -213.705 759.025 -299.044 799.474 -369.106C839.922 -439.166 906.965 -493.675 991.243 -516.276L849.661 175.656ZM1150.08 153.782L970.086 202.013L876.491 227.091L836.968 237.681L828.939 239.833L809.62 245.009L804.444 225.691L802.292 217.661L713.219 -114.764L713.218 -114.766C659.991 -313.483 777.882 -517.713 976.595 -570.958C1175.31 -624.202 1379.54 -506.286 1432.78 -307.571C1484.28 -115.368 1375.62 82.0441 1188.58 142.92L1188.72 143.43L1169.4 148.606L1150.08 153.782Z"
          fill="white"
          stroke="white"
          stroke-width="40"
        />
        <path
          d="M590.706 624.179L578.531 627.441L304.939 700.75L285.62 705.927C201.338 728.51 134.281 783.038 93.832 853.101C53.3972 923.162 39.7013 1008.48 62.2804 1092.75C84.8643 1177.03 139.392 1244.09 209.438 1284.55C279.496 1325 364.827 1338.68 449.116 1316.11L590.706 624.179ZM590.706 624.179L672.451 929.256C695.035 1013.54 681.342 1098.88 640.892 1168.94C600.444 1239 533.402 1293.51 449.123 1316.11L590.706 624.179ZM290.283 646.053L470.28 597.823L563.875 572.744L603.398 562.154L611.428 560.003L630.746 554.826L635.923 574.145L638.074 582.175L727.147 914.6L727.148 914.602C780.376 1113.32 662.484 1317.55 463.771 1370.79C265.06 1424.04 60.8294 1306.12 7.58384 1107.41C-43.9168 915.204 64.744 717.792 251.783 656.916L251.646 656.406L270.964 651.23L290.283 646.053Z"
          fill="white"
          stroke="white"
          stroke-width="40"
        />
      </g>
    </svg>
  );
};

RainforestBackground.defaultProps = {
  className: "",
  fill: "",
  width: "16px",
  height: "16px",
};

export const RainforestLogo = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width="138"
      height="32"
      viewBox="0 0 138 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63.5745 15.3354C63.5745 17.37 61.9656 19.0252 59.9878 19.0252C58.0101 19.0252 56.4012 17.37 56.4012 15.3354C56.4012 13.3007 58.0101 11.6452 59.9878 11.6452H63.5745V15.3354ZM54.606 15.3354C54.606 18.3932 57.0155 20.8719 59.9878 20.8719C62.9602 20.8719 65.3697 18.3932 65.3697 15.3354C65.3697 13.7479 65.3697 9.79883 65.3697 9.79883C65.3697 9.79883 61.4171 9.79883 59.9878 9.79883C57.0155 9.79883 54.606 12.2775 54.606 15.3354Z"
        fill="#00E57D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M96.1106 19.0252C94.1329 19.0252 92.524 17.37 92.524 15.3354C92.524 13.3007 94.1329 11.6452 96.1106 11.6452C98.0884 11.6452 99.6973 13.3007 99.6973 15.3354C99.6973 17.37 98.0884 19.0252 96.1106 19.0252ZM96.1106 9.79883C93.1386 9.79883 90.729 12.2775 90.729 15.3354C90.729 18.3932 93.1386 20.8719 96.1106 20.8719C99.083 20.8719 101.492 18.3932 101.492 15.3354C101.492 12.2775 99.083 9.79883 96.1106 9.79883Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M89.6495 7.1799H91.0445V5.3335H89.6495C87.1222 5.3335 85.0659 7.4487 85.0659 10.049V20.6586H86.8609V11.6644H89.1152V9.81776H86.8723C86.9874 8.34389 88.1875 7.1799 89.6495 7.1799Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M136.605 19.0199C135.067 19.0199 133.816 17.733 133.816 16.1508V11.6682H136.092V9.82149H133.816V5.3335H132.021V16.1508C132.021 18.7511 134.077 20.8666 136.605 20.8666H138V19.0199H136.605Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M68.2983 20.6577H70.0933V10.0073H68.2983V20.6577Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.2315 5.3335C68.6187 5.3335 68.1221 5.84443 68.1221 6.47457C68.1221 7.1047 68.6187 7.61563 69.2315 7.61563C69.8442 7.61563 70.3406 7.1047 70.3406 6.47457C70.3406 5.84443 69.8442 5.3335 69.2315 5.3335Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M112.309 14.5381C112.47 12.7218 113.902 11.4373 115.851 11.4373C117.82 11.4373 119.036 12.7218 119.155 14.5381H112.309ZM115.797 9.79834C112.697 9.79834 110.479 12.1682 110.479 15.3351C110.479 18.613 112.879 20.8722 116.356 20.8722C117.745 20.8722 119.047 20.5509 120.253 19.8311L119.951 18.2365C118.563 19.0117 117.486 19.2223 116.281 19.2223C114.279 19.2223 112.557 18.0703 112.266 15.9778H120.716C121.275 12.7106 119.295 9.79834 115.797 9.79834Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M127.279 14.6741L126.203 14.4525C124.75 14.1645 124.039 13.8437 124.039 12.9245C124.039 11.9498 124.965 11.3741 126.644 11.3741C127.656 11.3741 128.796 11.5957 129.636 11.9389L129.851 10.3994C129.044 10.0562 127.785 9.8125 126.536 9.8125C123.964 9.8125 122.338 11.0752 122.338 12.9688C122.338 14.6077 123.436 15.5378 125.148 15.881L126.493 16.1578C127.946 16.4458 128.743 16.8445 128.743 17.7968C128.743 18.7824 127.849 19.3138 126.192 19.3138C124.943 19.3138 123.619 19.0149 122.672 18.5165L122.435 20.0893C123.404 20.5874 124.846 20.8866 126.31 20.8866C128.818 20.8866 130.422 19.7792 130.422 17.7082C130.422 16.0472 129.335 15.0949 127.279 14.6741Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M77.8948 9.79883C76.7841 9.79883 75.7631 10.1938 74.9551 10.8532V10.0068H73.1602V20.6572H74.9551V14.5346C75.0246 12.9298 76.3183 11.6452 77.8948 11.6452C79.4715 11.6452 80.7652 12.9298 80.8347 14.5346V20.6572H82.6297V14.5346C82.5595 11.9074 80.4656 9.79883 77.8948 9.79883Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.6443 10.8029V10.008H47.8496V20.6789H49.6443V14.5367C49.6443 12.9552 50.8955 11.668 52.4331 11.668H53.8279V9.82129H52.4331C51.3834 9.82129 50.4181 10.1898 49.6443 10.8029Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M105.715 10.8029V10.008H103.92V20.6789H105.715V14.5367C105.715 12.9552 106.966 11.668 108.504 11.668H109.899V9.82129H108.504C107.454 9.82129 106.489 10.1898 105.715 10.8029Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.9568 22.1076C20.8365 20.6447 19.4402 18.1572 19.4402 15.3335C19.4402 11.1418 22.5154 7.69056 26.4708 7.23323C26.4708 7.8159 26.4708 13.1252 26.4708 15.3335C26.4708 18.1567 25.0761 20.6442 22.9568 22.1076ZM15.9233 23.0276C17.0303 22.082 17.9305 20.8895 18.543 19.5311C19.1556 20.89 20.0553 22.0839 21.1628 23.0295C20.3432 23.3244 19.4632 23.4868 18.5456 23.4868C17.6262 23.4868 16.7444 23.3239 15.9233 23.0276ZM10.6204 15.3335C10.6204 11.1439 13.6925 7.69403 17.6454 7.23403C17.6454 7.82043 17.6454 13.1263 17.6454 15.3335C17.6454 18.1564 16.2493 20.6415 14.1303 22.1047C12.0137 20.641 10.6204 18.1548 10.6204 15.3335ZM21.7411 7.1799C20.8513 7.83083 20.0729 8.63163 19.4402 9.5471C19.4402 8.6807 19.4402 7.86203 19.4402 7.1799C20.1071 7.1799 20.9143 7.1799 21.7411 7.1799ZM27.3656 5.3335C25.3638 5.3335 20.563 5.3335 18.5456 5.3335C13.1775 5.3335 8.82544 9.81056 8.82544 15.3335C8.82544 20.8564 13.1775 25.3335 18.5456 25.3335C23.914 25.3335 28.2658 20.8564 28.2658 15.3335C28.2658 12.466 28.2658 5.3335 28.2658 5.3335C28.2658 5.3335 27.925 5.3335 27.3656 5.3335Z"
        fill="#00E57D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.2662 5.3335C28.2662 5.3335 28.2662 6.07723 28.2662 7.1799C30.782 7.1799 35.2911 7.1799 35.2911 7.1799C35.2911 7.1799 35.2911 12.9956 35.2911 15.3335C35.2911 19.8362 31.7429 23.4868 27.3659 23.4868C26.4491 23.4868 25.5686 23.326 24.7495 23.0316C24.1998 23.5012 23.5979 23.9074 22.9556 24.2444C24.2791 24.9399 25.7775 25.3335 27.3659 25.3335C32.7341 25.3335 37.0861 20.8564 37.0861 15.3335C37.0861 12.466 37.0861 5.3335 37.0861 5.3335C37.0861 5.3335 31.3046 5.3335 28.2662 5.3335Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3392 23.0287C11.5193 23.3247 10.6386 23.4868 9.7202 23.4868C5.34326 23.4868 1.795 19.8362 1.795 15.3335C1.795 10.8303 5.34326 7.1799 9.7202 7.1799C10.4639 7.1799 11.671 7.1799 12.9216 7.1799C14.5095 6.01856 16.4492 5.3335 18.5459 5.3335C16.5479 5.3335 11.7397 5.3335 9.7202 5.3335C4.35206 5.3335 0 9.81056 0 15.3335C0 20.8564 4.35206 25.3335 9.7202 25.3335C11.3096 25.3335 12.8086 24.9386 14.1329 24.2426C13.4909 23.9052 12.889 23.4986 12.3392 23.0287Z"
        fill="#FFFFFE"
      />
    </svg>
  );
};

RainforestLogo.defaultProps = {
  className: "",
  fill: "",
  width: "16px",
  height: "16px",
};

export const AngleIcon = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.15463 6.2418L7.32963 0.432422C7.53276 0.229297 7.77651 0.107422 8.02026 0.107422C8.22338 0.107422 8.46713 0.229297 8.67026 0.391797L14.8453 6.20117C15.2515 6.5668 15.2515 7.17617 14.8859 7.58242C14.5203 7.98867 13.9109 7.98867 13.5046 7.62305L7.97963 2.42305L2.49526 7.62305C2.08901 7.98867 1.47963 7.98867 1.11401 7.58242C0.748382 7.2168 0.748382 6.60742 1.15463 6.2418Z"
        fill="white"
      />
    </svg>
  );
};

AngleIcon.defaultProps = {
  className: "",
  fill: "",
  width: "16px",
  height: "8px",
};

export const TurnDownLeftIcon = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.78906 9.10938L1.16406 6.67188C0.929688 6.46094 0.929688 6.0625 1.16406 5.85156L3.78906 3.41406C3.95312 3.25 4.1875 3.22656 4.39844 3.32031C4.60938 3.39062 4.75 3.60156 4.75 3.83594V5.5H6.125C6.3125 5.5 6.5 5.33594 6.5 5.125V1C6.5 0.601562 6.82812 0.25 7.25 0.25C7.64844 0.25 8 0.601562 8 1V5.125C8 6.17969 7.15625 7 6.125 7H4.75V8.6875C4.75 8.92188 4.60938 9.13281 4.39844 9.20312C4.1875 9.29688 3.95312 9.27344 3.78906 9.10938Z"
        fill="white"
      />
    </svg>
  );
};

TurnDownLeftIcon.defaultProps = {
  className: "",
  fill: "",
  width: "16px",
  height: "8px",
};

export const ArrowIcon = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5241 7.19697L8.64915 12.072C8.46633 12.2548 8.22258 12.3462 7.97883 12.3462C7.70461 12.3462 7.46086 12.2548 7.27805 12.072C6.88196 11.7063 6.88196 11.0665 7.27805 10.7009L10.4773 7.47119H1.15383C0.605395 7.47119 0.178833 7.04463 0.178833 6.49619C0.178833 5.97822 0.605395 5.52119 1.15383 5.52119H10.4773L7.27805 2.32197C6.88196 1.95635 6.88196 1.3165 7.27805 0.950879C7.64368 0.554785 8.28352 0.554785 8.64915 0.950879L13.5241 5.82588C13.9202 6.1915 13.9202 6.83135 13.5241 7.19697Z"
        fill="white"
      />
    </svg>
  );
};

ArrowIcon.defaultProps = {
  className: "",
  fill: "",
  width: "14px",
  height: "13px",
};

export const CheckIcon = (props) => {
  const { className, width, height, fill } = props;
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 23 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.875 2.5C22.875 2.95703 22.6719 3.36328 22.3672 3.66797L9.36719 16.668C9.0625 16.9727 8.65625 17.125 8.25 17.125C7.79297 17.125 7.38672 16.9727 7.08203 16.668L0.582031 10.168C0.277344 9.86328 0.125 9.45703 0.125 9C0.125 8.08594 0.835938 7.375 1.75 7.375C2.15625 7.375 2.5625 7.57812 2.86719 7.88281L8.25 13.2148L20.082 1.38281C20.3867 1.07812 20.793 0.875 21.25 0.875C22.1133 0.875 22.875 1.58594 22.875 2.5Z"
        fill={fill}
      />
    </svg>
  );
};

CheckIcon.defaultProps = {
  className: "",
  fill: "white",
  width: "23px",
  height: "18px",
};

export const CheckOptionIcon = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.42188 11.7344C5.73438 12.0469 6.26562 12.0469 6.57812 11.7344L15.7656 2.54688C16.0781 2.23438 16.0781 1.70312 15.7656 1.39062L14.6406 0.265625C14.3281 -0.046875 13.8281 -0.046875 13.5156 0.265625L6.01562 7.76562L2.48438 4.26562C2.17188 3.95312 1.67188 3.95312 1.35938 4.26562L0.234375 5.39062C-0.078125 5.70312 -0.078125 6.23438 0.234375 6.54688L5.42188 11.7344Z"
        fill="#27806E"
      />
    </svg>
  );
};

CheckOptionIcon.defaultProps = {
  className: "",
  fill: "white",
  width: "16px",
  height: "12px",
};

export const SpinnerIcon = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 14C1.5 11.1383 2.44429 8.35647 4.18641 6.0861C5.92853 3.81572 8.37112 2.18364 11.1354 1.44296C13.8996 0.702289 16.831 0.894423 19.4749 1.98957C22.1188 3.08471 24.3275 5.02166 25.7583 7.5C27.1892 9.97834 27.7623 12.8596 27.3888 15.6968C27.0153 18.5341 25.7159 21.1688 23.6924 23.1924C21.6688 25.2159 19.0341 26.5152 16.1968 26.8888C13.3596 27.2623 10.4783 26.6892 8 25.2583"
        stroke="white"
        stroke-width="2"
      />
    </svg>
  );
};

SpinnerIcon.defaultProps = {
  className: "",
  fill: "",
  width: "29px",
  height: "28px",
};

export const RocketIcon = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 27 27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.77734 18.1758C1.45703 19.4453 0.847656 22.7461 1.05078 26.25C4.55469 26.4531 7.85547 25.793 9.125 24.4727C11.1562 22.3906 11.2578 19.6992 9.42969 17.8203C7.55078 15.9922 4.80859 16.0938 2.77734 18.1758ZM6.94141 22.4414C6.48438 22.8477 5.41797 23.0508 4.25 23C4.19922 21.832 4.35156 20.7656 4.80859 20.3086C5.51953 19.6484 6.43359 19.5977 7.04297 20.207C7.65234 20.8164 7.60156 21.7305 6.94141 22.4414ZM26.6445 1.26562C26.543 0.960938 26.3398 0.65625 25.9844 0.605469C24.3594 0.25 23.0898 0.25 21.8203 0.25C16.5898 0.25 13.4414 3.09375 11.1055 6.75H5.77344C4.96094 6.75 3.99609 7.35938 3.64062 8.12109L1.10156 13.1484C1.05078 13.3008 1 13.5039 1 13.6562C1 14.3672 1.50781 14.875 2.21875 14.875H7.5C10.1406 14.875 12.375 17.0586 12.375 19.75V25.0312C12.375 25.7422 12.8828 26.25 13.543 26.25C13.6953 26.25 13.8984 26.1992 14.0508 26.1484L19.0781 23.6602C19.8398 23.2539 20.4492 22.2891 20.4492 21.4766V16.1445C24.1055 13.8086 26.8984 10.6602 26.8984 5.42969C27 4.16016 27 2.89062 26.6445 1.26562ZM19.1797 14.1133C17.8594 14.9258 18.7734 14.4688 14.1016 16.7539C13.3906 15.1289 12.0703 13.8594 10.4961 13.1484C12.7812 8.47656 12.3242 9.39062 13.1367 8.07031C15.5234 4.3125 18.1133 2.6875 21.8203 2.6875C22.7344 2.6875 23.5469 2.6875 24.4102 2.83984C24.5625 3.75391 24.5625 4.56641 24.5625 5.42969C24.5117 9.08594 22.9375 11.6758 19.1797 14.1133ZM19.6367 5.53125C18.5703 5.53125 17.6562 6.44531 17.6562 7.5625C17.6562 8.73047 18.5195 9.59375 19.6875 9.59375C20.7539 9.59375 21.668 8.73047 21.668 7.5625C21.668 6.44531 20.8047 5.53125 19.6367 5.53125Z"
        fill="#04243A"
      />
    </svg>
  );
};

RocketIcon.defaultProps = {
  className: "",
  fill: "",
  width: "27px",
  height: "27px",
};

export const GlassesIcon = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.1484 13.5195L26.8125 4.27734C26.5078 3.05859 25.7461 2.04297 24.6289 1.43359C23.5625 0.875 22.293 0.722656 21.0742 1.12891L20.3125 1.38281C19.8555 1.53516 19.6523 1.99219 19.8047 2.39844L20.0586 3.16016C20.1602 3.61719 20.668 3.82031 21.0742 3.71875L21.7344 3.46484C22.293 3.3125 22.9023 3.3125 23.4102 3.56641C23.9688 3.82031 24.3242 4.27734 24.4766 4.88672L26.4062 12.707C25.2891 12.3516 23.8672 12.0469 22.293 12.0469C20.2617 12.0469 17.9258 12.5039 15.5391 13.9258H13.6602C11.2734 12.5039 8.9375 12.0469 6.90625 12.0469C5.33203 12.0469 3.91016 12.3516 2.79297 12.707L4.72266 4.88672C4.875 4.27734 5.23047 3.82031 5.78906 3.56641C6.29688 3.3125 6.90625 3.3125 7.46484 3.46484L8.125 3.71875C8.53125 3.82031 9.03906 3.61719 9.14062 3.16016L9.39453 2.39844C9.54688 1.99219 9.34375 1.53516 8.88672 1.38281L8.125 1.12891C6.95703 0.722656 5.63672 0.875 4.57031 1.43359C3.45312 2.04297 2.69141 3.05859 2.38672 4.27734L0.0507812 13.5195C0 13.7734 0 14.0273 0 14.2812V17.8867C0 21.0352 2.58984 23.625 5.83984 23.625H7.71875C10.7656 23.625 13.3047 21.2891 13.5586 18.293L13.7109 16.3125H15.4883L15.6406 18.293C15.8945 21.2891 18.4336 23.625 21.4805 23.625H23.3594C26.6094 23.625 29.1992 21.0352 29.1992 17.8867V14.2812C29.25 14.0273 29.1992 13.7734 29.1484 13.5195ZM11.1211 18.0898C10.9688 19.8672 9.49609 21.1875 7.71875 21.1875H5.83984C3.96094 21.1875 2.38672 19.6641 2.38672 17.8359V15.3984C3.40234 14.9922 5.02734 14.4844 6.90625 14.4844C8.42969 14.4844 9.90234 14.7891 11.3242 15.4492L11.1211 18.0898ZM26.8125 17.8867C26.8125 19.7148 25.2383 21.1875 23.3594 21.1875H21.4805C19.7031 21.1875 18.2305 19.8672 18.0781 18.0898L17.875 15.4492C19.2969 14.7891 20.7695 14.4844 22.293 14.4844C24.1719 14.4844 25.7969 14.9922 26.8125 15.3984V17.8867Z"
        fill="#04243A"
      />
    </svg>
  );
};

GlassesIcon.defaultProps = {
  className: "",
  fill: "",
  width: "30px",
  height: "24px",
};

export const CrossIcon = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 8 8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.05704 6.15061C7.32111 6.39436 7.32111 6.82092 7.05704 7.06467C6.93517 7.18655 6.77267 7.24749 6.61017 7.24749C6.42736 7.24749 6.26486 7.18655 6.14298 7.06467L4.01017 4.93186L1.85704 7.06467C1.73517 7.18655 1.57267 7.24749 1.41017 7.24749C1.22736 7.24749 1.06486 7.18655 0.942981 7.06467C0.678918 6.82092 0.678918 6.39436 0.942981 6.15061L3.07579 3.99749L0.942981 1.86467C0.678918 1.62092 0.678918 1.19436 0.942981 0.95061C1.18673 0.686548 1.61329 0.686548 1.85704 0.95061L4.01017 3.08342L6.14298 0.95061C6.38673 0.686548 6.81329 0.686548 7.05704 0.95061C7.32111 1.19436 7.32111 1.62092 7.05704 1.86467L4.92423 4.0178L7.05704 6.15061Z"
        fill="#232323"
      />
    </svg>
  );
};

CrossIcon.defaultProps = {
  className: "",
  fill: "",
  width: "8px",
  height: "8px",
};

export const DocumentIcon = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.85633"
        y="13.7586"
        width="39.4242"
        height="49.7273"
        rx="2.5"
        transform="rotate(-16.0537 2.85633 13.7586)"
        fill="#18374A"
        stroke="white"
        stroke-width="3"
      />
      <line
        x1="12.113"
        y1="20.3946"
        x2="36.4293"
        y2="13.3973"
        stroke="white"
        stroke-linecap="round"
      />
      <line
        x1="13.4538"
        y1="25.054"
        x2="37.7701"
        y2="18.0568"
        stroke="white"
        stroke-linecap="round"
      />
      <line
        x1="14.7946"
        y1="29.7134"
        x2="32.7042"
        y2="24.5597"
        stroke="white"
        stroke-linecap="round"
      />
      <path
        d="M57.5332 31.0979L42.822 26.1096C42.6123 26.0386 42.3851 26.039 42.1757 26.111L27.6749 31.0961C27.2711 31.2349 27 31.6132 27 32.0402V45.2675C27 54.892 39.7263 61.023 42.0819 62.0754C42.3206 62.182 42.5878 62.1834 42.8273 62.0785C45.2102 61.0352 58.2121 54.9016 58.2121 45.2675V32.0433C58.2121 31.6147 57.9391 31.2355 57.5332 31.0979Z"
        fill="#18374A"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M51.5454 38.7221C51.5454 39.0971 51.3885 39.4304 51.1531 39.6804L41.1098 50.3471C40.8744 50.5971 40.5606 50.722 40.2467 50.722C39.8936 50.722 39.5798 50.5971 39.3444 50.3471L34.3227 45.0137C34.0873 44.7637 33.9697 44.4304 33.9697 44.0554C33.9697 43.3054 34.5189 42.7221 35.2251 42.7221C35.5389 42.7221 35.8528 42.8887 36.0882 43.1387L40.2467 47.5137L49.3877 37.8054C49.6231 37.5554 49.9369 37.3887 50.29 37.3887C50.9569 37.3887 51.5454 37.9721 51.5454 38.7221Z"
        fill="white"
      />
    </svg>
  );
};

DocumentIcon.defaultProps = {
  className: "",
  fill: "",
  width: "64px",
  height: "64px",
};

export const DollarIcon = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="32"
        cy="32"
        r="30.5"
        fill="#18374A"
        stroke="white"
        stroke-width="3"
      />
      <path
        d="M41.9155 37.9509C41.9155 40.11 41.0675 41.9607 39.3716 43.5029C37.6756 45.0452 35.4979 45.9319 32.8383 46.1633V51.4261C32.8383 51.696 32.5685 51.831 32.0289 51.831C31.4507 51.831 31.1617 51.696 31.1617 51.4261V46.2211C29.4657 46.1826 27.8083 45.9512 26.1895 45.5271C24.5706 45.103 23.4528 44.621 22.8361 44.0813C22.5278 43.8885 22.3736 43.4836 22.3736 42.8668L22.0845 36.3315C22.0845 36.1773 22.1809 36.0809 22.3736 36.0424C22.5663 36.0038 22.7012 36.0617 22.7783 36.2159C24.1273 38.5292 25.4379 40.457 26.7098 41.9993C28.0203 43.5029 29.5043 44.4283 31.1617 44.7753V33.7869L29.4272 32.8615C27.1531 31.6277 25.4186 30.4711 24.2237 29.3915C23.0288 28.2734 22.4314 26.8468 22.4314 25.1118C22.4314 23.0683 23.2601 21.4104 24.9175 20.1381C26.5749 18.8272 28.6563 18.0561 31.1617 17.8248V13.0824C31.1617 12.9282 31.2388 12.8125 31.3929 12.7354C31.5856 12.6583 31.7976 12.6197 32.0289 12.6197C32.2602 12.6197 32.4529 12.6583 32.6071 12.7354C32.7612 12.8125 32.8383 12.9282 32.8383 13.0824V17.7669C34.0332 17.8055 35.2666 17.9597 36.5386 18.2296C37.8105 18.4609 38.832 18.7501 39.6028 19.0971C39.9497 19.2128 40.181 19.367 40.2966 19.5598C40.4123 19.714 40.4701 19.9453 40.4701 20.2538V25.748C40.4701 25.8636 40.3737 25.96 40.181 26.0371C39.9883 26.0757 39.8534 26.0371 39.7763 25.9215C39.1596 24.6106 38.2923 23.2611 37.1746 21.8731C36.0568 20.4851 34.6114 19.5983 32.8383 19.2128V28.9288L34.2259 29.6228C35.9604 30.5482 37.3095 31.3386 38.2731 31.994C39.2752 32.6109 40.1232 33.4206 40.817 34.423C41.5493 35.4255 41.9155 36.6014 41.9155 37.9509ZM27.2302 22.8563C27.2302 23.9744 27.5771 24.9576 28.2708 25.8058C28.9646 26.6155 29.9282 27.3673 31.1617 28.0613V19.0971C29.8126 19.1357 28.8105 19.5019 28.1552 20.1959C27.5385 20.8899 27.2302 21.7767 27.2302 22.8563ZM32.8383 44.8909C33.9561 44.8138 34.8812 44.409 35.6135 43.6764C36.3844 42.9439 36.7698 41.9221 36.7698 40.6112C36.7698 39.416 36.4229 38.3557 35.7291 37.4304C35.0354 36.505 34.0717 35.6375 32.8383 34.8279V44.8909Z"
        fill="white"
      />
    </svg>
  );
};

DocumentIcon.defaultProps = {
  className: "",
  fill: "",
  width: "64px",
  height: "64px",
};

DocumentIcon.defaultProps = {
  className: "",
  fill: "",
  width: "64px",
  height: "64px",
};

export const ClockIcon = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7 8.80716V13.3072C13.7 13.4009 13.6699 13.4779 13.6096 13.5382C13.5493 13.5985 13.4723 13.6286 13.3786 13.6286H10.1643C10.0705 13.6286 9.99353 13.5985 9.93326 13.5382C9.87299 13.4779 9.84286 13.4009 9.84286 13.3072V12.6643C9.84286 12.5706 9.87299 12.4935 9.93326 12.4333C9.99353 12.373 10.0705 12.3429 10.1643 12.3429H12.4143V8.80716C12.4143 8.71341 12.4444 8.6364 12.5047 8.57613C12.565 8.51586 12.642 8.48573 12.7357 8.48573H13.3786C13.4723 8.48573 13.5493 8.51586 13.6096 8.57613C13.6699 8.6364 13.7 8.71341 13.7 8.80716L13.7 8.80716ZM17.1453 15.0851C17.6342 14.248 17.8786 13.334 17.8786 12.3429C17.8786 11.3518 17.6342 10.4378 17.1453 9.60071C16.6565 8.76366 15.9935 8.10071 15.1565 7.61187C14.3194 7.12303 13.4054 6.87861 12.4143 6.87861C11.4232 6.87861 10.5092 7.12303 9.6721 7.61187C8.83505 8.10071 8.1721 8.76366 7.68326 9.60071C7.19443 10.4378 6.95001 11.3518 6.95001 12.3429C6.95001 13.334 7.19443 14.248 7.68326 15.0851C8.1721 15.9221 8.83505 16.5851 9.6721 17.0739C10.5092 17.5628 11.4232 17.8072 12.4143 17.8072C13.4054 17.8072 14.3194 17.5628 15.1565 17.0739C15.9935 16.5851 16.6565 15.9221 17.1453 15.0851ZM19.094 8.47572C19.7837 9.65429 20.1286 10.9434 20.1286 12.3429C20.1286 13.7425 19.7837 15.0332 19.094 16.2151C18.4042 17.397 17.4684 18.3329 16.2865 19.0226C15.1046 19.7123 13.8138 20.0572 12.4143 20.0572C11.0147 20.0572 9.724 19.7123 8.54208 19.0226C7.36016 18.3329 6.42433 17.397 5.7346 16.2151C5.04487 15.0332 4.7 13.7425 4.7 12.3429C4.7 10.9434 5.04487 9.65262 5.7346 8.4707C6.42433 7.28878 7.36016 6.35295 8.54208 5.66322C9.724 4.97349 11.0147 4.62862 12.4143 4.62862C13.8138 4.62862 15.1046 4.97349 16.2865 5.66322C17.4684 6.35295 18.4042 7.29045 19.094 8.47572Z"
        fill="white"
      />
    </svg>
  );
};

ClockIcon.defaultProps = {
  className: "",
  fill: "",
  width: "25px",
  height: "25px",
};

ClockIcon.defaultProps = {
  className: "",
  fill: "",
  width: "25px",
  height: "25px",
};

export const InfoIcon = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0.75C4.00781 0.75 0 4.79297 0 9.75C0 14.7422 4.00781 18.75 9 18.75C13.957 18.75 18 14.7422 18 9.75C18 4.79297 13.957 0.75 9 0.75ZM9 5.25C9.59766 5.25 10.125 5.77734 10.125 6.375C10.125 7.00781 9.59766 7.5 9 7.5C8.36719 7.5 7.875 7.00781 7.875 6.375C7.875 5.77734 8.36719 5.25 9 5.25ZM10.4062 14.25H7.59375C7.10156 14.25 6.75 13.8984 6.75 13.4062C6.75 12.9492 7.10156 12.5625 7.59375 12.5625H8.15625V10.3125H7.875C7.38281 10.3125 7.03125 9.96094 7.03125 9.46875C7.03125 9.01172 7.38281 8.625 7.875 8.625H9C9.45703 8.625 9.84375 9.01172 9.84375 9.46875V12.5625H10.4062C10.8633 12.5625 11.25 12.9492 11.25 13.4062C11.25 13.8984 10.8633 14.25 10.4062 14.25Z"
        fill="white"
      />
    </svg>
  );
};

InfoIcon.defaultProps = {
  className: "",
  fill: "",
  width: "25px",
  height: "25px",
};

InfoIcon.defaultProps = {
  className: "",
  fill: "",
  width: "25px",
  height: "25px",
};

export const ArrowRightIcon = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      className={className}
      width="19"
      height="12"
      viewBox="0 0 19 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7773 11.6289C11.6016 11.4883 11.5312 11.2773 11.5312 11.0312C11.5312 10.8203 11.6016 10.6094 11.7773 10.4688L15.1172 7.09375H0.84375C0.351562 7.09375 0 6.74219 0 6.25C0 5.79297 0.351562 5.40625 0.84375 5.40625H15.1172L11.7773 2.06641C11.4258 1.75 11.4258 1.22266 11.7773 0.90625C12.0938 0.554688 12.6211 0.554688 12.9727 0.90625L17.7539 5.6875C18.0703 6.00391 18.0703 6.53125 17.7539 6.84766L12.9727 11.6289C12.6211 11.9805 12.0938 11.9805 11.7773 11.6289Z"
        fill="white"
      />
    </svg>
  );
};

ArrowRightIcon.defaultProps = {
  className: "",
  fill: "",
  width: "25px",
  height: "25px",
};

ArrowRightIcon.defaultProps = {
  className: "",
  fill: "",
  width: "25px",
  height: "25px",
};

export const WarningIcon = (props) => {
  const { className, width, height } = props;
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7891 13.9102L11.3008 1.11328C10.7031 0.128906 9.26172 0.128906 8.69922 1.11328L1.17578 13.9102C0.613281 14.8945 1.31641 16.125 2.47656 16.125H17.4883C18.6484 16.125 19.3516 14.8945 18.7891 13.9102ZM9.15625 5.15625C9.15625 4.69922 9.50781 4.3125 10 4.3125C10.457 4.3125 10.8438 4.69922 10.8438 5.15625V9.65625C10.8438 10.1484 10.457 10.5 10 10.5C9.57812 10.5 9.15625 10.1484 9.15625 9.65625V5.15625ZM10 13.875C9.36719 13.875 8.875 13.3828 8.875 12.7852C8.875 12.1875 9.36719 11.6953 10 11.6953C10.5977 11.6953 11.0898 12.1875 11.0898 12.7852C11.0898 13.3828 10.5977 13.875 10 13.875Z"
        fill="#E9A100"
      />
    </svg>
  );
};

WarningIcon.defaultProps = {
  className: "",
  fill: "",
  width: "25px",
  height: "25px",
};

WarningIcon.defaultProps = {
  className: "",
  fill: "",
  width: "25px",
  height: "25px",
};
