import React from "react";
import GreenButton from "./GreenButton";
import { TurnDownLeftIcon } from "./Icons";
import classNames from "classnames";

const ConfirmButton = ({ className, text, showHint, onConfirm }) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-start relative",
        className
      )}
    >
      <GreenButton
        onCLick={onConfirm}
        className="rounded-lg h-12 font-bold text-lg"
      >
        {text}
      </GreenButton>
      {showHint && (
        <div className="hidden md:flex absolute left-full items-center justify-start w-full ml-4">
          <p className="mr-1 text-sm flex-shrink-0 font-normal">
            press <span className="font-bold">Enter</span>
          </p>
          <TurnDownLeftIcon className="flex-shrink-0" />
        </div>
      )}
    </div>
  );
};

export default ConfirmButton;
