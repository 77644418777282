import React from "react";

const WelcomeSection = () => {
  return (
    <div className="p-2">
      <p className="text-4xl md:text-6xl font-bold mb-6">HELLO!</p>
      <p className="text-xl md:text-2xl font-light">
        We’ll ask you a series of questions, please answer them to the best of your ability and we’ll give you an estimated valuation.
      </p>
    </div>
  );
};

export default WelcomeSection;
