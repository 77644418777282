import React from "react";

const QuestionsSection = ({ title, description }) => {
  return (
    <div>
      <p className="text-center md:text-left text-2xl md:text-4xl mb-3 font-bold">{title}</p>
      <p className="text-center md:text-left text-xl md:text-2xl opacity-80 font-normal">{description}</p>
    </div>
  );
};

export default QuestionsSection;
