import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CheckIcon, SpinnerIcon } from "./Icons";
import classNames from "classnames";

const LOADING_MESSAGES = [
  "Performing pre-emptive numbers analysis...",
  "Accessing e-commerce gurus’ mind...",
  "Generating accurate predictive algorithms...",
  "Unlocking the answers to successful businesses...",
  "Weighting products impact....",
];

const getLoadingMessage = (msg, index, processingMessageIndex) => {
  const getStatusIcon = () => {
    if (processingMessageIndex > index) {
      return <CheckIcon className="absolute mr-1 md:mr-3 right-full h-3 md:h-auto" />;
    }
    return (
      <SpinnerIcon
        className={classNames(
          "transition-all duration-500 absolute animate-spin mr-1 md:mr-3 right-full opacity-0 h-3 md:h-auto",
          {
            "opacity-0": processingMessageIndex < index,
            "opacity-100": processingMessageIndex === index,
          }
        )}
      />
    );
  };
  return (
    <div className="flex items-center justify-start md:justify-center mb-4 md:mb-12 px-8 md:px-12">
      <div
        className="relative flex items-center transition-all duration-500 "
        style={{ opacity: `${100 - (index - processingMessageIndex) * 40}%` }}
      >
        {getStatusIcon()}
        <div className="flex items-center justify-center text-xs md:text-2xl flex-shrink-0">
          {msg}
        </div>
      </div>
    </div>
  );
};

const ResultsLoader = ({ show, setResultsLoaderDone }) => {
  const [processingMessageIndex, setProcessingMessageIndex] = useState(-1);
  const timeoutHandler = (value) => {
    if (value > LOADING_MESSAGES.length) {
      setResultsLoaderDone(true);
      return;
    }
    setProcessingMessageIndex(value);
    setTimeout(() => timeoutHandler(value + 1), 1000);
  };
  useEffect(() => {
    if (!show) return;
    timeoutHandler(processingMessageIndex);
    return () => {
      setProcessingMessageIndex(-1);
    };
  }, [show]);
  return (
    <div
      className={classNames(
        "transition-all duration-1000 w-full flex flex-col",
        {
          "h-[16rem] md:h-[35rem]": processingMessageIndex > -1,
          "h-4 md:h-[6rem]": processingMessageIndex === -1,
          "opacity-0": processingMessageIndex >= LOADING_MESSAGES.length,
        }
      )}
    >
      <div className="text-3xl md:text-6xl font-bold h-[2rem] md:h-[6rem]">
        Preparing Valuation
      </div>
      <div className="overflow-hidden relative mt-8 md:mt-16 h-[16] md:h-[25rem]">
        {LOADING_MESSAGES.map((item, index) =>
          getLoadingMessage(item, index, processingMessageIndex)
        )}
      </div>
    </div>
  );
};

ResultsLoader.propTypes = {};

export default ResultsLoader;
