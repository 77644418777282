import { useEffect, useCallback } from "react";

export const useKeyPress = (targetKey, callback) => {
  const downHandler = useCallback(
    ({ key }) => {
      console.log("key", key);
      if (!targetKey || key === targetKey) callback(key);
    },
    [callback, targetKey]
  );
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [downHandler]);
};
