import React from "react";
import { CalendlyEventListener, InlineWidget } from "react-calendly";
import { sendConsultationScheduled } from "../network";

const TalkToExpertsSection = ({ valuationResults }) => {
  const onScheduled = (event) => {
    const dataToSend = {
      valuation_id: valuationResults.valuation_id,
      person: valuationResults.person,
      email: valuationResults.email,
      payload: event?.data?.payload,
    };
    sendConsultationScheduled(dataToSend);
  };
  return (
    <div
      id="talk-to-experts"
      className="bg-white px-2 md:px-0 py-8 md:py-20 bg-[#FAFAFA]"
    >
      <div className="text-center text-gray-black text-2xl md:text-5xl font-bold font-cormorant">
        TALK TO OUR EXPERTS
      </div>
      <div className="text-center text-gray-black mt-4 mb-16">
        Your business qualifies for a free call with our specialized resources.
        Claim it below!
      </div>
      <CalendlyEventListener onEventScheduled={onScheduled}>
        <InlineWidget
          url="https://calendly.com/yi3/15?hide_gdpr_banner=1"
          styles={{ height: "650px" }}
        />
      </CalendlyEventListener>
    </div>
  );
};

TalkToExpertsSection.propTypes = {};

export default TalkToExpertsSection;
