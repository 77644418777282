import React from "react";
import StyledTextInput from "./StyledTextInput";
import NumberFormat from "react-number-format";

const CurrencyFormat = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      // fixedDecimalScale
      decimalScale={2}
      prefix={"$"}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
    />
  );
};

const PositiveIntegerFormat = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      fixedDecimalScale
      decimalScale={0}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({ target: { name: props.name, value: values.value } });
      }}
      thousandSeparator
    />
  );
};

const getInputExtraProps = (validator) => {
  if (validator === "Currency") {
    return {
      InputProps: {
        inputComponent: CurrencyFormat,
      },
    };
  } else if (validator === "PositiveInteger") {
    return {
      InputProps: {
        inputComponent: PositiveIntegerFormat,
      },
    };
  } else {
    return {};
  }
};

const TextQuestion = ({
  value,
  onChange,
  helperText,
  helperLink,
  error,
  active,
  validator,
  labelText,
}) => {
  const onChangeInput = (value) => {
    if (!active) return;
    onChange(value);
  };

  const extraProps = getInputExtraProps(validator);
  return (
    <div className="w-full">
      {helperText && (
        <div className="flex items-center mt-4 md:text-xl">
          <div className="opacity-80 text-left">{helperText}</div>
          {helperLink && (
            <>
              <div className="rounded-full w-1 h-1 bg-white opacity-50 mx-4" />
              <a className="underline text-green-light cursor-pointer">
                {helperLink}
              </a>
            </>
          )}
        </div>
      )}
      <StyledTextInput
        value={value}
        error={error}
        helperText={error}
        className="mt-9 text-sm"
        onChange={onChangeInput}
        label={labelText || "Enter your answer"}
        {...extraProps}
      />
    </div>
  );
};

export default TextQuestion;
