import React from "react";
import "../rf-styles.css";
import FooterLogo from "../images/footer_logo.png";

const Footer = () => {
  return (
    <div className="rf-original-box">
      <body>
        <div className="section image-bg-green wf-section">
          <div className="container">
            <div className="works-container pb-16">
              <h2 className="heading-6">Here’s how it works:</h2>
              <div className="how-it-works-info-container">
                <div className="how-it-works-day">Step 1</div>
                <div
                  className="dot"
                  id="w-node-_87b6f296-b810-153b-9365-da0d6a948f32-524dd215"
                />
                <div className="how-it-works-heading">Review</div>
                <div className="blank" />
                <div
                  id="w-node-a7b699ba-0f4f-71ea-866a-b969c2f26e30-524dd215"
                  className="line"
                />
                <p className="how-it-works-paragraph">
                  We’ll review your business in just a few days. If your Brand
                  fits our portfolio and we agree on an estimated valuation, we
                  will sign a Letter of Intent.
                </p>
                <div className="how-it-works-day">Step 2</div>
                <div
                  id="w-node-e1c2c3c4-27f9-9780-c996-525cba20780f-524dd215"
                  className="dot"
                />
                <div className="how-it-works-heading">Learn</div>
                <div className="blank" />
                <div
                  id="w-node-_212d7613-d9d3-c3d1-d471-2c40cecb97e0-524dd215"
                  className="line"
                />
                <div className="how-it-works-paragraph">
                  We’ll get to know your business in-depth. Simultaneously, we
                  will prepare the legal documents needed to facilitate this
                  process.
                </div>
                <div className="how-it-works-day">Step 3</div>
                <div
                  id="w-node-b54328b9-9cf5-e450-b285-f26313774d48-524dd215"
                  className="dot"
                />
                <div className="how-it-works-heading">Finalise and Exit</div>
                <div className="blank" />
                <div
                  id="w-node-d37ae0a2-044d-2298-c418-d3cc98a108e5-524dd215"
                  className="works-link-container"
                >
                  <div className="line dotted d1" />
                  <div className="line dotted d2" />
                  <div className="line dotted d3" />
                  <div className="line dotted d4" />
                  <div className="line dotted d4" />
                </div>
                <div className="how-it-works-paragraph">
                  The last step is to migrate your assets to Rainforest and
                  transfer the acquisition amount to you from an escrow account.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section mint-bg wf-section rf-original-box">
          <div className="container">
            <div className="footer-container">
              <div className="footer-left">
                <div className="footer-logo-container">
                  <a
                    href="/"
                    aria-current="page"
                    className="footer-logo-container w-inline-block w--current"
                  >
                    <img
                      src={FooterLogo}
                      loading="lazy"
                      alt=""
                      className="footer-logo"
                    />
                  </a>
                </div>
                <p className="footer-paragraph">
                  Rainforest buys e-commerce brands and grows them with a focus
                  on long-term success. We believe that being part of Rainforest
                  improves the brands’ longevity, financial returns and
                  sustainability outcomes.
                </p>
              </div>
              <div className="footer-right">
                <div className="link-container">
                  <a href="/privacy-policy" className="footer-link">
                    Privacy Policy
                  </a>
                  <a href="/team" className="footer-link">
                    About us
                  </a>
                  <a href="/cookie-policy" className="footer-link">
                    Cookie Policy
                  </a>
                  <a href="/careers" className="footer-link">
                    Careers
                  </a>
                  <div className="blank"></div>
                  <a href="/referrals" className="footer-link">
                    Referrals
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="footer-copyright">
                © Copyright Rainforest Life Pte Ltd.
              </div>
              <div className="footer__btns-wrap">
                {/*<a*/}
                {/*  data-w-id="c085ffc3-38f7-ba5a-2b38-c81503528216"*/}
                {/*  href="#"*/}
                {/*  className="footer-button w-button"*/}
                {/*>*/}
                {/*  Get In Touch*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
};

export default Footer;
