import React from "react";
import { TextField } from "@mui/material";

const StyledTextInput = ({ ...props }) => {
  return (
    <TextField
      id={Date.now()}
      fullWidth
      sx={{
        root: {
          backgroundColor: "white !important",
        },
        label: {
          color: "white",
          fontWeight: 400,
          fontSize: {
            xs: "1.25rem",
            md: "2rem",
          },
          opacity: "50%",
          "&.MuiInputLabel-root.Mui-focused": {
            color: "white",
            fontSize: {
              xs: "1.125rem",
              md: "1.5rem",
            },
          },
          "&.MuiInputLabel-root.MuiFormLabel-filled": {
            fontSize: {
              xs: "1.125rem",
              md: "1.5rem",
            },
          },
        },
        "& input": {
          color: "white",
          fontSize: {
            xs: "1.25rem",
            md: "2rem",
          },
        },
        "& .MuiInputBase-input": { color: "#fff !important" },
        "& .MuiInput-underline:before": {
          borderBottomColor: "#fff8 !important",
        },
        "& .MuiInput-underline:hover:before": {
          borderBottomColor: "#fff8 !important",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "#fff8 !important",
        },
        "& .MuiInput-root:hover::before": { borderColor: "#fff8 !important" },
        "& .MuiFormHelperText-root": { color: "#fff8 !important" },
        "& .MuiInputLabel-root.Mui-error": { color: "#fff8 !important" },
        "& .MuiFormHelperText-root.Mui-error": { color: "#FABE28 !important" },
        "& .MuiOutlinedInput-notchedOutline": { border: 0 },
      }}
      {...props}
      variant="standard"
    />
  );
};

export default StyledTextInput;
