import React, { useEffect, useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import QuestionnaireWrapper from "./QuestionnaireWrapper";
import ContentWrapper from "./ContentWrapper";
import CarouselWrapper from "./CarouselWrapper";
import WelcomeSection from "./WelcomeSection";
import { useKeyPress } from "../hooks";
import QuestionsSection from "./QuestionsSection";
import TextQuestion from "./TextQuestion";
import CardsInput from "./CardsInput";
import ResultsLoader from "./ResultsLoader";
import classNames from "classnames";
import ResultsPageTopSection from "./ResultsPageTopSection";
import ResultsMiddleSection from "./ResultsMiddleSection";
import TalkToExpertsSection from "./TalkToExpertsSection";
import Footer from "./Footer";
import { postQuestions, pullSlidesConfig } from "../network";
import { validateEmail, validatePositiveInteger } from "../validators";
import { ArrowRightIcon, ClockIcon, InfoIcon, WarningIcon } from "./Icons";
import StyledTextInput from "./StyledTextInput";
import Background from "../images/green.png";

const getSectionDescriptionContent = (
  slideData,
  formData,
  setFormData,
  onNextClick,
  activeSlide
) => {
  return (
    <ContentWrapper
      confirmText={"CONTINUE"}
      showConfirmHint
      leftAligned
      onConfirm={onNextClick}
    >
      <QuestionsSection
        title={slideData.category}
        description={slideData.text}
      />
    </ContentWrapper>
  );
};
const getTextQuestionContent = (
  slideData,
  formData,
  setFormData,
  onNextClick,
  activeSlide,
  formErrors,
  setFormErrors
) => {
  return (
    <ContentWrapper
      serialNumber={slideData.question_id}
      title={slideData.text}
      leftAligned
      confirmText="OK"
      showConfirmHint
      onConfirm={() => {
        onNextClick();
      }}
    >
      <TextQuestion
        labelText={slideData.label_text}
        value={formData[slideData.id]}
        onChange={(event) => setFormData(slideData.id, event.target.value)}
        helperText={slideData.help_text}
        helperLink={slideData.help_link}
        validator={slideData.validator}
        error={formErrors[slideData.id]}
        active={activeSlide}
      />
    </ContentWrapper>
  );
};

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#27806E",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#27806E",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#27806E",
      },
      daySelected: {
        backgroundColor: "#27806E",
        "&:hover": {
          backgroundColor: "#1b6556",
        },
      },
      dayDisabled: {
        color: "#767776",
      },
      current: {
        color: "#27806E",
      },
    },
    MuiPickersMonth: {
      root: {
        color: "#27806E",
      },
      monthSelected: {
        color: "#27806E !important",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#27806E",
      },
      yearSelected: {
        color: "#27806E",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#27806E",
      },
    },
    MuiDialogActions: {
      root: {
        color: "#27806E !important",
        "& .MuiButton-textPrimary": {
          color: "#27806E",
        },
      },
    },
  },
});

const getDateQuestionContent = (
  slideData,
  formData,
  setFormData,
  onNextClick,
  activeSlide,
  formErrors,
  setFormErrors
) => {
  const onSetDate = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setFormData(slideData.id, formattedDate);
  };
  const value = formData[slideData.id] || null;
  return (
    <ContentWrapper
      serialNumber={slideData.id}
      title={slideData.text}
      leftAligned
      confirmText="OK"
      showConfirmHint
      onConfirm={() => {
        if (!formData[slideData.id]) return;
        onNextClick();
      }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme}>
          <DatePicker
            disableFuture
            openTo="year"
            format="MMM yyyy"
            label=""
            placeholder="Select Date"
            value={value}
            views={["year", "month"]}
            onChange={onSetDate}
            TextFieldComponent={StyledTextInput}
            className="mt-8"
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </ContentWrapper>
  );
};

const getCardsQuestionContent = (
  slideData,
  formData,
  setFormData,
  onNextClick,
  activeSlide,
  formErrors,
  setFormErrors,
  setNextBlocked
) => {
  const isMultiSelect = slideData.type === "Multiselect";
  const onClick = (value) => {
    if (isMultiSelect) {
      const exclusiveValue = slideData.option.find(
        (item) => item.exclusive
      )?.id;
      if (exclusiveValue === value) {
        setFormData(slideData.id, [value]);
      } else {
        const selectedData = formData[slideData.id] || [];
        const alreadySelected = selectedData.find((item) => item === value);
        const filteredSelected = selectedData.filter(
          (item) => item !== exclusiveValue
        );
        if (alreadySelected) {
          setFormData(
            slideData.id,
            filteredSelected.filter((item) => item !== value)
          );
        } else {
          setFormData(slideData.id, [...filteredSelected, value]);
        }
      }
    } else {
      setFormData(slideData.id, value);
      if (slideData.type !== "Multiselect")
        setTimeout(() => onNextClick("force"), 750);
    }
  };
  return (
    <ContentWrapper
      serialNumber={slideData.question_id}
      title={slideData.text}
      confirmText="OK"
      leftAligned
      showConfirmHint
      onConfirm={() => {
        if (!formData[slideData.id]) return;
        onNextClick();
      }}
    >
      <CardsInput
        labelText={slideData.label_text}
        multiSelect={isMultiSelect}
        active={activeSlide}
        className="mt-12"
        onCardClick={onClick}
        selectedValue={formData[slideData.id]}
        cards={slideData.option}
        setNextBlocked={setNextBlocked}
      />
    </ContentWrapper>
  );
};

const getSlideViewFunc = (slideData) => {
  if (slideData.validator === "Date") {
    return getDateQuestionContent;
  } else if (slideData.category) {
    return getSectionDescriptionContent;
  } else if (!slideData.option?.length) {
    return getTextQuestionContent;
  } else {
    return getCardsQuestionContent;
  }
};

const getSlides = (
  welcomeSlide,
  resultsLoaderSlide,
  slidesConfig,
  formData,
  setFormData,
  onNextClick,
  selectedSlideIndex,
  formErrors,
  setFormErrors,
  setNextBlocked
) => {
  const result = [welcomeSlide];
  slidesConfig.forEach((slideData, index) => {
    const getContent = getSlideViewFunc(slideData);
    result.push(
      getContent(
        slideData,
        formData,
        setFormData,
        onNextClick,
        selectedSlideIndex === index + 1,
        formErrors,
        setFormErrors,
        setNextBlocked
      )
    );
  });
  result.push(resultsLoaderSlide);
  return result;
};

const prepareQuestions = (categories) => {
  const result = [];
  categories.forEach((categoryData) => {
    if (!categoryData.id) return;
    if (categoryData.text) {
      result.push({
        category: categoryData.category,
        text: categoryData.text,
        optional: true,
      });
    }
    result.push(...categoryData.question);
  });
  let questionNumber = 1;
  result.forEach((item) => {
    if (!item.id) return;
    item.question_id = questionNumber;
    questionNumber += 1;
  });
  return result;
};
const ResumePreviousSessionPopup = ({ show, onClick }) => {
  if (!show) return null;
  return (
    <div
      onClick={onClick}
      className="w-full absolute flex items-center justify-center z-20 cursor-pointer"
    >
      <div className="mx-8 mt-8 p-6 rounded bg-green-lighter flex flex-wrap items-center justify-center">
        <InfoIcon className="mr-auto md:mr-0 mb-3 md:mb-0" />
        <div className="flex items-center flex-nowrap">
          <div className="relative md:ml-2 mr-auto md:mr-5 ">
            You previously started a Business Valuation. Click to resume where
            you left off.
            <div className="opacity-50 absolute w-full h-1px border-b border-dashed border-white" />
          </div>
          <ArrowRightIcon className="flex-shrink-0 mt-2 md:mt-0 self-start md:self-auto" />
        </div>
      </div>
    </div>
  );
};

const Questionnaire = () => {
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
  const [resultsLoaderDone, setResultsLoaderDone] = useState(false);
  const [nextBlocked, setNextBlocked] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(false);
  const [slidesConfig, setSlidesConfig] = useState([]);
  const [valuationResults, setValuationResults] = useState(null);
  const [getQuestionsError, setGetQuestionsError] = useState(false);
  const [error, setError] = useState(false);
  const [formData, _setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [prevSessionData, setPrevSessionData] = useState(
    JSON.parse(localStorage.getItem("formData"))
  );
  const setFormData = (questionId, value) => {
    const newFormData = { ...formData, [questionId]: value };
    localStorage.setItem("formData", JSON.stringify(newFormData));
    _setFormData(newFormData);
  };
  useEffect(() => {
    pullSlidesConfig(
      (data) => setSlidesConfig(prepareQuestions(data)),
      setGetQuestionsError
    );
  }, []);

  const continuePreviousSession = () => {
    const filledQuestionIds = Object.keys(prevSessionData)
      .map((item) => +item)
      .sort((a, b) => b - a);
    const lastFilledQuestionId = filledQuestionIds[0];
    const lastFilledSlideIndex = slidesConfig.findIndex(
      (el) => el.id === +lastFilledQuestionId
    );
    setSelectedSlideIndex(lastFilledSlideIndex + 1);
    setPrevSessionData(null);
    _setFormData(prevSessionData);
  };
  const onPrevClick = () => {
    if (nextBlocked) return;
    if (
      !selectedSlideIndex ||
      resultsLoaderDone ||
      selectedSlideIndex === resultsLoaderSlideIndex
    )
      return;
    setSelectedSlideIndex(selectedSlideIndex - 1);
  };
  const onNextClick = () => setSelectedSlideIndex(selectedSlideIndex + 1);
  const getErrorView = (text) => {
    return (
      <div className="mt-2 flex items-center">
        <WarningIcon />
        <div className="ml-1">{text}</div>
      </div>
    );
  };
  const validateCurrentInput = (
    currentQuestionId,
    questionValidator,
    skipEmail
  ) => {
    console.log("questionValidator", questionValidator);
    const questionValue = formData[currentQuestionId];
    if (questionValidator === "OptionalUrl") {
      if (!questionValue) return true;
      const urlValid = `${questionValue || ""}`.match(/\S+\.\S+/gm);
      setFormErrors({
        ...formErrors,
        [currentQuestionId]: urlValid
          ? ""
          : getErrorView(`Enter a valid URL. You can also leave this blank.`),
      });
      return urlValid;
    } else if (questionValidator === "Optional") {
      if (!(currentQuestionId in formData)) setFormData(currentQuestionId, "");
      return true;
    } else if (questionValidator === "Date") {
      return !!questionValue;
    } else if (questionValidator === "Currency") {
      return !!questionValue;
    } else if (questionValidator === "String") {
      return !!questionValue;
    } else if (questionValidator === "Email") {
      const emailValid = validateEmail(questionValue);
      if (questionValue && !skipEmail) {
        setFormErrors({
          ...formErrors,
          [currentQuestionId]: emailValid
            ? ""
            : getErrorView("Please enter an email."),
        });
      }
      return emailValid;
    } else if (questionValidator === "PositiveInteger") {
      return questionValue;
    }
    return true;
  };

  const validateForm = (skipEmail) => {
    const currentSlideData = slidesConfig[selectedSlideIndex - 1];
    console.log("currentSlideData", currentSlideData);
    if (!currentSlideData) return true;
    const isMultiSelect = currentSlideData?.type === "Multiselect";
    const currentQuestionId = currentSlideData?.id;
    const questionValidator = currentSlideData?.validator;
    if (questionValidator) {
      return validateCurrentInput(
        currentQuestionId,
        questionValidator,
        skipEmail
      );
    }
    if (!slidesConfig.length) return false;
    if (!selectedSlideIndex) return true;
    const isOptional = !!currentSlideData.optional;
    const questionAnswer = isMultiSelect
      ? formData[currentSlideData.id]?.length
      : formData[currentSlideData.id];
    return (questionAnswer || isOptional) && !formErrors[currentSlideData.id];
  };

  useEffect(() => {
    setNextEnabled(validateForm(true));
  }, [formData, selectedSlideIndex]);

  const pressEnterHandler = (value) => {
    const isValid = validateForm();
    if (!isValid && value !== "force") return;
    if (nextBlocked) return;
    onNextClick();
  };

  useKeyPress("Enter", pressEnterHandler);
  useKeyPress("ArrowUp", onPrevClick);
  const timeWidget = (
    <div className="mt-6 flex items-center justify-center">
      <ClockIcon className="mr-2" />
      <div className="text-base font-normal">Takes 7 Min</div>
    </div>
  );
  const welcomeSlide = (
    <ContentWrapper
      confirmText={"LET'S GO!"}
      showConfirmHint
      onConfirm={pressEnterHandler}
      bottomSection={timeWidget}
    >
      <WelcomeSection />
    </ContentWrapper>
  );

  const resultsLoaderSlideIndex = slidesConfig.length + 1;

  useEffect(() => {
    if (selectedSlideIndex !== resultsLoaderSlideIndex) return;
    postQuestions(formData, setValuationResults, setError);
  }, [selectedSlideIndex, resultsLoaderSlideIndex]);

  const resultsLoaderSlide = (
    <ContentWrapper>
      <ResultsLoader
        show={selectedSlideIndex === resultsLoaderSlideIndex}
        setResultsLoaderDone={setResultsLoaderDone}
      />
    </ContentWrapper>
  );

  const slides = getSlides(
    welcomeSlide,
    resultsLoaderSlide,
    slidesConfig,
    formData,
    setFormData,
    pressEnterHandler,
    selectedSlideIndex,
    formErrors,
    setFormErrors,
    setNextBlocked
  );
  const loadingDone = valuationResults && resultsLoaderDone;
  const getQuestionnaireWrapperContent = () => {
    if ((resultsLoaderDone && error) || getQuestionsError)
      return (
        <div className="md:text-2xl text-center">
          <div>Oops. Seems like our eCommerce gurus need a break.</div>
          <div>
            If this error persists please contact us at
            acquisitions@rainforest.life.
          </div>
        </div>
      );
    if (!loadingDone) {
      return (
        <CarouselWrapper
          slides={slides}
          selectedSlideIndex={selectedSlideIndex}
        />
      );
    }

    return (
      <ResultsPageTopSection
        name={valuationResults?.person}
        valuationValue={valuationResults?.score}
        striveList={valuationResults?.strive_list || []}
        improveList={valuationResults?.improve_list || []}
        schedule_call={valuationResults?.schedule_call}
      />
    );
  };
  const welcomeBottomSection = (
    <div className="mb-8 flex-shrink-0 text-xs align-center hidden lg:flex justify-center text-center max-w-[35rem] font-light text-xs opacity-80">
      Disclaimer: The Rainforest Valuation Tool aims to give you an estimated
      valuation for your brand. There are many factors involved. The result
      shall not be taken as an offer. Feel free to contact us for an accurate
      valuation. We value your privacy and no information will be shared with
      any other parties without your explicit consent.
    </div>
  );
  return (
    <>
      <div
        className={classNames(
          "transition-all duration-1000 w-screen bg-gray-black",
          {
            "p-1 md:p-8": loadingDone,
          }
        )}
        style={{ backgroundImage: `url(${Background})` }}
      >
        <ResumePreviousSessionPopup
          onClick={continuePreviousSession}
          show={prevSessionData && slidesConfig.length && !selectedSlideIndex}
        />
        <QuestionnaireWrapper
          shading={selectedSlideIndex !== resultsLoaderSlideIndex}
          onDownClick={pressEnterHandler}
          onUpClick={onPrevClick}
          hideUpDownButtons={
            !selectedSlideIndex ||
            selectedSlideIndex === resultsLoaderSlideIndex
          }
          nextEnabled={nextEnabled}
          carouselView={!loadingDone}
          bottomSection={
            !selectedSlideIndex && !getQuestionsError && welcomeBottomSection
          }
        >
          {getQuestionnaireWrapperContent()}
        </QuestionnaireWrapper>
        {loadingDone && <ResultsMiddleSection />}
      </div>
      {loadingDone && (
        <>
          {valuationResults?.schedule_call && (
            <TalkToExpertsSection valuationResults={valuationResults} />
          )}
          <Footer />
        </>
      )}
    </>
  );
};
export default Questionnaire;
