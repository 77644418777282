import React from "react";
import classNames from "classnames";
import GreenButton from "./GreenButton";
import { AngleIcon } from "./Icons";

const UpDownButtons = ({ className, hidden, onUpClick, onDownClick, nextEnabled }) => {
  if (hidden) return null;
  return (
    <div className={classNames("flex items-center", className)}>
      <GreenButton onCLick={onUpClick} className="rounded-r-none h-12 w-12 min-w-0" >
        <AngleIcon />
      </GreenButton>
      <div
        className="w-px bg-white opacity-10 h-12"
        style={{ left: "1px", marginLeft: "-1px" }}
      />
      <GreenButton disabled={!nextEnabled} onCLick={onDownClick} className="rounded-l-none h-12 w-12 min-w-0">
        <AngleIcon className="rotate-180" />
      </GreenButton>
    </div>
  );
};

export default UpDownButtons;
