import React from "react";
import ReactDOM from "react-dom";
import "./main.css";
import App from "./App";

console.log = () => {};
console.error = () => {};
console.warn = () => {};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
