import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CarouselWrapper = ({ slides, selectedSlideIndex }) => {
  return (
    <Carousel
      selectedItem={selectedSlideIndex}
      axis="vertical"
      dynamicHeight={true}
      showIndicators={false}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      swipeable={false}
    >
      {slides}
    </Carousel>
  );
};

CarouselWrapper.propTypes = {
  slides: PropTypes.instanceOf(Array).isRequired,
  selectedSlideIndex: PropTypes.number.isRequired,
};

export default CarouselWrapper;
