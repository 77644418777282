import React from "react";

import classNames from "classnames";
import { Button } from "@mui/material";

const GreenButton = ({ children, onCLick, className, disabled }) => {
  return (
    <Button
      disabled={disabled}
      variant="contained"
      disableElevation
      className={classNames(
        {
          "opacity-50": disabled,
        },
        "bg-green-default rounded-lg",
        className
      )}
      onClick={onCLick}
      style={{ fontFamily: "Montserrat" }}
    >
      {children}
    </Button>
  );
};

export default GreenButton;
