import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NoImage from '../images/no-image-available.png';

const SafeImage = ({ src, className, alt, style }) => {
  const [srcError, setSrcError] = useState(false);
  return (
    <img
      onError={() => setSrcError(true)}
      src={srcError || !src ? NoImage : src}
      alt={alt}
      className={className}
      style={style}
    />
  );
};

SafeImage.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.instanceOf(Object)
};

SafeImage.defaultProps = {
  className: '',
  alt: '',
  style: {}
};

export default SafeImage;
